import React, { useState, useEffect } from "react";
import {
    Form,
    Row,
    Col,
    Input,
    AutoComplete,
    DatePicker,
    Select,
    Typography,
    Spin,
    Space,
    Switch
} from "antd";
import apiCall from "../../services/apiCall";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { ReactComponent as LocationPin } from "../../assets/icon_pin.svg";
import { ReactComponent as ValidationLogo } from "../../assets/icon_alert.svg";
import PageHead from "../../components/PageHead";
import { useHistory, useLocation } from "react-router-dom";
import useViewPort from "../../services/responsiveHelper";
import FooterMenu from "../../components/FooterMenu";
import ConfirmModal from "../../components/ConfirmModal";
import ToastNotification from "../../helpers/ToastNotification";
import { removeIdFieldStateData } from "../../helpers/StateHelper";

const ClientProctorForm = () => {
    const history = useHistory();
    const location = useLocation();
    const { width } = useViewPort();
    const breakpoint = 670;
    const [form] = Form.useForm();
    const dateFormat = "MMMM Do YYYY";
    const [disableForm, setDisableForm] = useState(false);
    const [workorderDetails, setWorkorderDetails] = useState();
    const [workorderValue, setWorkorderValue] = useState();
    const [loading, setLoading] = useState(false);
    const [workOrderLoading, setWorkOrderLoading] = useState(false);
    const userId = location?.state?.id;
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleActivate, setModalVisibleActivate] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [disableEmail, setDisableEmail] = useState(false);
    const [clientValue, setClientValue] = useState();
    const [testType, setTestType] = useState("TTC");
    const [status, setStatus] = useState();
    const [isWreOpted, setIsWreOpted] = useState(true);
    const { Text } = Typography;
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 36,
                color: "rgb(0, 48, 135)",
                fontWeight: "bold"
            }}
            spin
        />
    );

    const onFinish = (formValues) => {
        delete formValues["startdate"];
        delete formValues["enddate"];
        delete formValues["woaddress"];
        formValues["testType"] = testType;
        formValues.isWreOpted = isWreOpted;

        if (clientValue?.email) {
            if (status && status !== "Pending") {
                formValues.status = status;
            }
            // delete formValues["workOrderNo"];
            formValues.workOrderNo = workorderValue;
            formValues.clientId = workorderDetails?.clientId;
            formValues.clientName = workorderDetails?.clientName;
            formValues.testType = testType || clientValue.testType;
            editClient(formValues, clientValue._id);
        } else {
            if (workorderDetails)
                formValues["clientName"] = workorderDetails.clientName;
            formValues["clientId"] = workorderDetails.clientId;
            addClient(formValues);
        }
    };
    const checkInActive = status === "InActive" ? "Inactive" : null;
    const checkStatus = status === "Active" ? "Active" : checkInActive;
    const checkClassInActive = status === "InActive" ? "switch-disabled" : null;
    const checkClassName = status === "Active" ? "switch" : checkClassInActive;

    const phShowSaveIf = showSave
        ? {
              title: clientValue?.firstName + " " + clientValue?.lastName,
              toggleStatus: checkStatus,
              onChangeSwitch: (change) => {
                  if (!change) setModalVisible(true);
                  else if (change) setModalVisibleActivate(true);
              },
              toggleClassname: checkClassName,
              switchDisabled: false,
              defaultChecked: status === "Active" ? true : false,
              btnAdd: "Save",
              backBtnPath: "/client-proctor",
              routeState: removeIdFieldStateData(location.state)
          }
        : {
              title: clientValue?.firstName + " " + clientValue?.lastName,
              toggleStatus: checkStatus,
              switchDisabled: true,
              onChangeSwitch: (change) => {
                  if (!change) setModalVisible(true);
                  else if (change) setModalVisibleActivate(true);
              },
              defaultChecked: status === "Active" ? true : false,
              toggleClassname: checkClassName,
              btnUpdate: "Edit",
              backBtnPath: "/client-proctor",
              routeState: removeIdFieldStateData(location.state)
          };
    const phShowSaveElse = {
        title: "New Client Proctor",
        btnAdd: "Save",
        backBtnPath: "/client-proctor",
        routeState: removeIdFieldStateData(location.state)
    };
    const phCheckClientValueIf = clientValue?.email
        ? phShowSaveIf
        : phShowSaveElse;
    const phCheckClientValueElse = clientValue?.email
        ? {
              title: clientValue?.firstName + " " + clientValue?.lastName,
              backBtnPath: "/client-proctor",
              routeState: removeIdFieldStateData(location.state)
          }
        : {
              title: "New Client Proctor",
              backBtnPath: "/client-proctor",
              routeState: removeIdFieldStateData(location.state)
          };
    const pageHeaderProps =
        width > breakpoint ? phCheckClientValueIf : phCheckClientValueElse;

    const showSaveIf = {
        toggleStatus: checkStatus,
        onChangeSwitch: (change) => {
            if (!change) setModalVisible(true);
            else if (change) setModalVisibleActivate(true);
        },
        toggleClassname: checkClassName,
        switchDisabled: false,
        defaultChecked: status === "Active" ? true : false,
        btnAdd: "Save",
        backBtnPath: "/client-proctor",
        routeState: removeIdFieldStateData(location.state)
    };

    const showSaveElse = {
        toggleStatus: checkStatus,
        switchDisabled: true,
        onChangeSwitch: (change) => {
            if (!change) setModalVisible(true);
            else if (change) setModalVisibleActivate(true);
        },
        defaultChecked: status === "Active" ? true : false,
        toggleClassname: checkClassName,
        btnUpdate: "Edit"
    };
    const isShowSave = showSave ? showSaveIf : showSaveElse;
    const pageFooterProps = clientValue?.email
        ? isShowSave
        : {
              btnAdd: "Save"
          };
    const confirmHandleModal = () => {
        setModalVisible(false);
        setStatus("InActive");
    };
    const confirmHandleModalActivate = () => {
        setModalVisibleActivate(false);
        setStatus("Active");
    };
    const onSearch = (searchText) => {
        console.log(searchText);
        //  setOptions (
        //     !searchText
        //       ? []
        //       : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)]
        //   );
    };
    const onSelect = (data) => {
        setWorkorderValue(data);
    };
    const onChange = (data) => {
        if (data.length === 6) setWorkorderValue(Number(data));
    };

    /** API calls */
    const resendInviteProctors = async () => {
        setLoading(true);
        const data = await apiCall.resendInviteProctors(clientValue.email);
        if (data.status === 200) {
            ToastNotification(
                "success",
                data ? data?.data : " Reset Activation link sent."
            );
        } else {
            ToastNotification("error", "Something went wrong.");
        }
        setLoading(false);
    };
    const getClient = async (user_Id) => {
        setLoading(true);
        const data = await apiCall.getClientById(user_Id);
        if (data.status === 200) {
            setStatus(data.data.status);
            setClientValue(data.data);
            if (data.data?.isWreOpted !== undefined) setIsWreOpted(data.data.isWreOpted);
            setTestType(data.data.testType);
            setWorkorderValue(JSON.stringify(data.data.workOrderNo));
        } else {
            ToastNotification("error", "Error fetching User records.");
        }
        setLoading(false);
    };
    const addClient = async (formValues) => {
        setLoading(true);
        const data = await apiCall.addClientProctor(formValues);
        if (data.status === 200) {
            setClientValue(data.data);
            setShowSave(false);
            setDisableForm(true);
            setDisableEmail(true);
            ToastNotification("success", "Record saved successfully.");
            history.push({
                pathname: `/client-proctor-form`,
                state: { ...location.state, id: data.data._id }
            });
        } else if (data.status === 400) {
            setLoading(false);
            form.setFields([
                {
                    name: "email",
                    errors: [data ? data.data : "Something went wrong."]
                }
            ]);
        } else {
            setLoading(false);
            ToastNotification("error", data?.data);
        }
        setLoading(false);
    };
    const editClient = async (formValues, userId) => {
        setLoading(true);
        const data = await apiCall.editClientProctor(formValues, userId);
        if (data.status === 200) {
            setClientValue({...clientValue, ...formValues});
            setShowSave(false);
            setDisableForm(true);
            setDisableEmail(true);
            ToastNotification("success", "Record saved successfully.");
        } else {
            setLoading(false);
            ToastNotification("error", data?.data || "Error fetching User records.");
        }
        setLoading(false);
    };
    const getWorkorderDetails = async (workorderId) => {
        console.log("checking work order details");
        setWorkOrderLoading(true);
        const data = await apiCall.getWorkorderDetails(workorderId);
        if (data.status === 200) {
            setWorkorderDetails(data.data);
        } else {
            if (showSave && data.status === 400) {
                console.log("first");
                setDisableForm(false);
            }
            form.resetFields(["workOrderNo"]);
            setWorkorderValue(null);
            setWorkorderDetails(null);
            ToastNotification(
                "error",
                data ? data?.data : "Error fetching User records."
            );
            form.setFields([
                {
                    name: "workOrderNo",
                    errors: [data ? data?.data : "Enter valid Work Order ID."]
                }
            ]);
        }
        setWorkOrderLoading(false);
    };

    useEffect(() => {
        if (workorderValue) getWorkorderDetails(workorderValue);
    }, [workorderValue]);
    useEffect(() => {
        if (userId && userId !== null) {
            setDisableForm(true);
            setDisableEmail(true);
            getClient(userId);
        }
    }, [userId]);

    const onToggleChange = (checked) => {
        setIsWreOpted(checked)
    }

    const checkStatusPending =
        status === "Pending" ? (
            <Col span={24}>
                <div className="login-validation-container">
                    <div>
                        <ValidationLogo />
                    </div>

                    <span className="login-validation-text">
                        Pending client activation.
                    </span>
                    <span className="resend-activation-link">
                        <span
                            className="resend-activation-link-text"
                            onClick={resendInviteProctors}
                        >
                            Resend Activation Link
                        </span>
                    </span>
                </div>
            </Col>
        ) : null;

    const displayWorkOrder =
        workorderValue && workorderValue?.length === 6 ? (
            <Form.Item label="Workorder" name="workOrderNo">
                <span className="client-form-search">
                    <AutoComplete
                        // options={options}
                        type="number"
                        className="autocomplete"
                        onSelect={onSelect}
                        onSearch={onSearch}
                        onChange={onChange}
                        disabled={disableForm}
                        defaultValue={workorderValue}
                        // filterOption={(inputValue, option) => {
                        //   option.value
                        //     .toUpperCase()
                        //     .indexOf(inputValue.toUpperCase()) !== -1;
                        // }}
                    />
                </span>
            </Form.Item>
        ) : (
            <Form.Item
                label="Workorder"
                name="workOrderNo"
                rules={[
                    {
                        required: true,
                        message: "Please enter valid Work Order."
                    },
                    {
                        max: 6,
                        message: "WorkOrder should be 6 digit Number."
                    },
                    {
                        min: 6,
                        message: "WorkOrder should be 6 digit Number."
                    }
                ]}
            >
                <span className="client-form-search">
                    <AutoComplete
                        // options={options}
                        type="number"
                        className="autocomplete"
                        onSelect={onSelect}
                        onSearch={onSearch}
                        onChange={onChange}
                        disabled={disableForm}
                        defaultValue={workorderValue}
                        // filterOption={(inputValue, option) => {
                        //   option.value
                        //     .toUpperCase()
                        //     .indexOf(inputValue.toUpperCase()) !== -1;
                        // }}
                    />
                </span>
            </Form.Item>
        );
    const displayWorkOrderDetails =
        workorderDetails && !workOrderLoading ? (
            <div>
                <Row>
                    <Col span={24}>
                        <Typography className="workorder-text">
                            Workorder Details
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Workorder Address"
                            name="woaddress"
                            initialValue={workorderDetails?.woAddressLine1}
                        >
                            <Input
                                disabled={true}
                                area-label="Workorder Address"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col span={11}>
                        <Form.Item
                            label="Start Date"
                            name="startdate"
                            initialValue={moment(workorderDetails?.startDate)}
                        >
                            <DatePicker
                                format={dateFormat}
                                disabled={true}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="End Date"
                            name="enddate"
                            initialValue={moment(workorderDetails?.endDate)}
                        >
                            <DatePicker
                                format={dateFormat}
                                disabled={true}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div className="workorder-facility">
                            <Typography className="workorder-text">
                                {workorderDetails?.clientName}
                            </Typography>
                            <div className="workorder-facility-details">
                                <div className="workorder-icon">
                                    <LocationPin />
                                </div>
                                <div>
                                    <Typography className="workorder-primary">
                                        {workorderDetails?.facilityName}
                                    </Typography>
                                    <Typography className="workorder-secondary">
                                        {workorderDetails?.facilityAddressLine1}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={24}>
                    <Form.Item label="Technician Name" name="techName">
                    <Input disabled={disableForm} />
                    </Form.Item>
                    </Col>
                    </Row> */}
                <Row>
                    <Col span={24}>
                        <Form.Item label="Subscription Type" name="testType">
                            <span className="client-form-search">
                                <Select
                                    className="client-form-search"
                                    value={testType}
                                    onSelect={(value) => {
                                        setTestType(value);
                                    }}
                                    disabled={disableForm}
                                >
                                    <Select.Option value="TO">
                                        Test Only(TO)
                                    </Select.Option>
                                    <Select.Option value="TN">
                                        Test Notify(TN)
                                    </Select.Option>
                                    <Select.Option value="TT">
                                        Test Train(TT)
                                    </Select.Option>
                                    <Select.Option value="TC">
                                        Test Counsel(TC)
                                    </Select.Option>
                                    <Select.Option value="TTN">
                                        Test Train Notify(TTN)
                                    </Select.Option>
                                    <Select.Option value="TTC">
                                        Test Train Counsel(TTC)
                                    </Select.Option>
                                </Select>
                            </span>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        ) : workOrderLoading ? (
            <Row justify="center">
                <Spin tip="Loading Workorder details..." />
            </Row>
        ) : null;

    const checkBreakpoint =
        width < breakpoint ? (
            <FooterMenu
                {...pageFooterProps}
                formName="examineticsAdminAddEditForm"
                onBtnClick={() => {
                    form.submit();
                }}
                onBtnEdit={() => {
                    setShowSave(true);
                    setDisableForm(false);
                    setDisableEmail(
                        clientValue?.status !== "Pending" ? true : false
                    );
                }}
            />
        ) : null;
    return loading ? (
        <Spin indicator={antIcon} className="loader" />
    ) : (
        <div className="main-container">
            <PageHead
                formName="examineticsAdminAddEditForm"
                {...pageHeaderProps}
                onBtnClick={() => {
                    form.submit();
                }}
                onBtnEdit={() => {
                    setShowSave(true);
                    setDisableForm(false);
                    setDisableEmail(
                        clientValue?.status !== "Pending" ? true : false
                    );
                }}
                divider={true}
            >
                <Row
                    style={{
                        margin: "30px 16px 20px 16px",
                        overflow: "auto",
                        height: "71vh"
                    }}
                >
                    <Col
                        xs={{ span: 22, offset: 1 }}
                        sm={{ span: 20, offset: 2 }}
                        md={{ span: 18, offset: 3 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 14, offset: 5 }}
                        xxl={{ span: 14, offset: 5 }}
                    >
                        <Form
                            form={form}
                            name="clientProctorAddEditForm"
                            size="large"
                            colon="false"
                            scrollToFirstError="true"
                            onFinish={onFinish}
                            layout="vertical"
                            requiredMark={true}
                            className="login-form"
                        >
                            <Row>
                                {checkStatusPending}
                                <Col span={24}>
                                    <Form.Item
                                        label="Client Proctor Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                type: "email",
                                                message:
                                                    "Please enter valid Email."
                                            }
                                        ]}
                                        initialValue={clientValue?.email}
                                    >
                                        <Input
                                            placeholder="Ex: jonathan@gmail.com"
                                            disabled={disableEmail}
                                            autoFocus
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter First Name."
                                            }
                                        ]}
                                        initialValue={clientValue?.firstName}
                                    >
                                        <Input
                                            placeholder="Ex: Tanya "
                                            disabled={disableForm}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter Last Name."
                                            }
                                        ]}
                                        initialValue={clientValue?.lastName}
                                    >
                                        <Input
                                            placeholder="Ex: Jonathan"
                                            disabled={disableForm}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>{displayWorkOrder}</Col>
                            </Row>
                            {displayWorkOrderDetails}
                            <Col span={24}>
                                    <Row justify="space-between" className="wre-opt-in-out-container">
                                        <Col>
                                            <Space
                                                direction="vertical"
                                                size={0}
                                            >
                                                <Text strong>
                                                    Opt-In for WRE (Work Related
                                                    Events)
                                                </Text>
                                                <Text type="secondary">
                                                    Toggle the switch to enable
                                                    or disable WRE
                                                </Text>
                                            </Space>
                                        </Col>
                                        <Col>
                                            <Switch
                                                defaultChecked={isWreOpted}
                                                onChange={onToggleChange}
                                                disabled={disableForm}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                        </Form>
                    </Col>
                </Row>
            </PageHead>
            {checkBreakpoint}
            <ConfirmModal
                title="De-activate user?"
                content="Please confirm if you wish to de-activate this user."
                visible={modalVisible}
                onConfirm={confirmHandleModal}
                onCancel={() => {
                    setModalVisible(false);
                    setStatus("Active");
                }}
            />
            <ConfirmModal
                title="Activate user?"
                content="Please confirm if you wish to Activate this user."
                visible={modalVisibleActivate}
                onConfirm={confirmHandleModalActivate}
                onCancel={() => {
                    setModalVisibleActivate(false);
                    setStatus("InActive");
                }}
            />
        </div>
    );
};
export default ClientProctorForm;
